/* eslint-env browser */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import querystring from 'querystring';
import UrlParser from './URLParser';
import Config from './Config';
import TokenRepository from './TokenRepository';
import SafariFocusFix from './SafariFocusFix';
import MessageHandler from './MessageHandler';
import TokenService from './TokenService';
import TokenResponse from './TokenResponse';
import { OuterGooglePayField } from './GooglePay';
import FieldFactory from './Field/FieldFactory';

import './polyfill';
import { ApplePayField } from './ApplePay';
import Field from './Field/Field';
import { isDevelopment } from './Hacks';

const safariFix = Symbol('safariFix');
let hasConstructorBeenCalled = false;

class CollectJS {
  constructor() {
    if (hasConstructorBeenCalled) {
      // eslint-disable-next-line no-console
      console.error('CollectJS.constructor should not be called');
      return window.CollectJS;
    }

    hasConstructorBeenCalled = true;
    const script = document.createElement('script');
    script.src = 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js';
    script.crossOrigin = 'crossorigin';
    document.head.appendChild(script);
    const dataset = { ...UrlParser.currentScriptNode.dataset };
    // The dataset can't be freely manipulated in Safari 10, so clone its values into something we can delete on later.
    this.config = new Config({ ...dataset });
    this.isIframeOpen = false;
    this.inSubmission = false;
    this.responseTimeout = 0;
    this[safariFix] = new SafariFocusFix();
    // this is done because this function is needed in removeEventListener.
    this.startPaymentRequest = this.startPaymentRequest.bind(this);
    MessageHandler.addMessageHandlersToWindow(this);

    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll(this.config.paymentSelector).forEach(payButton => {
        if (payButton instanceof Node) {
          payButton.addEventListener('click', this.startPaymentRequest);
        }
      });

      if (SafariFocusFix.isAppleDevice()) {
        this[safariFix].registerTouchStartListener();
      }
    });

    if (this.config.variant === 'inline') {
      this.iframes = {};
      MessageHandler.iframeResponses = [];
    }

    const params = querystring.stringify({
      tokenizationKey: this.config.tokenizationKey,
      cartCorrelationId: this.config.cartCorrelationId,
    });

    this.tokenPromise = TokenRepository.fetchToken(params).catch(() => {
      // eslint-disable-next-line no-console
      console.log('Giving up on retrieving token!');
    });

    this.tokenPromise.then(
      ({
        token,
        merchantId,
        merchantName,
        currencies,
        cardBrands,
        allowedApplePayDomains,
        applePayVersion,
        applePay: { currencies: applePayCurrencies, cardBrands: applePayCardBrands },
      }) => {
        this.fieldFactory = new FieldFactory(this.config);
        this.buildGooglePayButton(token, merchantId, merchantName, currencies, cardBrands);
        this.applePayVersion = applePayVersion;
        this.buildApplePayButton(
          token,
          applePayCurrencies,
          applePayCardBrands,
          allowedApplePayDomains,
        );

        if (this.config.variant === 'inline') {
          this.buildInlineIframes(token);
        }
      },
    );
  }

  retokenize() {
    const params = querystring.stringify({
      tokenizationKey: this.config.tokenizationKey,
      cartCorrelationId: this.config.cartCorrelationId,
    });

    clearTimeout(this.responseTimeout);
    MessageHandler.completedIframes = [];
    this.tokenPromise = TokenRepository.fetchToken(params);
    this.tokenPromise.then(
      ({
        token,
        merchantId,
        merchantName,
        currencies,
        cardBrands,
        allowedApplePayDomains,
        applePay: { currencies: applePayCurrencies, cardBrands: applePayCardBrands },
      }) => {
        this.buildGooglePayButton(token, merchantId, merchantName, currencies, cardBrands);
        this.buildApplePayButton(
          token,
          applePayCurrencies,
          applePayCardBrands,
          allowedApplePayDomains,
        );

        if (this.iframes) {
          Object.keys(this.iframes).forEach(key => {
            // Check to make sure that the content window still exists (e.g. its still on the page)
            if (this.iframes[key].contentWindow) {
              this.iframes[key].contentWindow.postMessage(
                {
                  token,
                  action: 'newToken',
                },
                '*',
              );
            }
          });
        }
        MessageHandler.callbackFiredAfterValidationSuccess = false;
      },
    );
  }

  /**
   * @param newObj
   */
  configure(newObj) {
    // by doing a spread here, it allows integrators to call configure without any parameters to reload CollectJS
    const obj = { ...newObj };
    if (typeof obj.paymentSelector === 'string') {
      document.querySelectorAll(this.config.paymentSelector).forEach(payButton => {
        if (payButton instanceof Node) {
          payButton.removeEventListener('click', this.startPaymentRequest);
        }
      });
    }

    this.config = this.config.update(obj);

    if (this.config.variant === 'inline') {
      this.tokenPromise.then(({ token }) => {
        this.buildInlineIframes(token);
      });

      if (SafariFocusFix.isAppleDevice()) {
        this[safariFix].registerTouchStartListener();
      }
    }

    this.tokenPromise.then(
      ({
        token,
        merchantId,
        merchantName,
        currencies,
        cardBrands,
        allowedApplePayDomains,
        applePay: { currencies: applePayCurrencies, cardBrands: applePayCardBrands },
      }) => {
        this.buildGooglePayButton(token, merchantId, merchantName, currencies, cardBrands);
        this.buildApplePayButton(
          token,
          applePayCurrencies,
          applePayCardBrands,
          allowedApplePayDomains,
        );
      },
    );

    if (typeof this.config.paymentSelector === 'string') {
      document.querySelectorAll(this.config.paymentSelector).forEach(payButton => {
        if (payButton instanceof Node) {
          payButton.addEventListener('click', this.startPaymentRequest);
        }
      });
    }
  }

  buildInlineIframes(token) {
    if (!this.iframes) {
      this.iframes = {};
    }
    MessageHandler.iframeResponses = [];
    if (this.config.variant !== 'inline') {
      throw new Error('CollectJS must be set to inline to build multiple iframes');
    }

    Object.keys(this.config.getInlineFields()).forEach(elementType => {
      if (!document.querySelector(this.config.fields[elementType].selector)) {
        return;
      }

      const element = document.querySelector(this.config.fields[elementType].selector);
      const styleset = {};
      const customStyleset = {};
      const invalidStyleset = {};
      const validStyleset = {};
      const placeholderStyleset = {};
      const focusStyleset = {};
      let sniffField = null;

      if (this.config.styleSniffer) {
        sniffField = document.createElement('input');
        sniffField.type = 'text';
        if (this.config.snifferClass) {
          sniffField.className = this.config.snifferClass;
        }
        element.appendChild(sniffField);
        const declaration = window.getComputedStyle(sniffField);

        for (let i = 0; i < declaration.length; i += 1) {
          styleset[declaration[i]] = declaration.getPropertyValue(declaration[i]);
        }

        // IE often miscalculates height by the standards of other browsers.  Explicitly
        // measuring it avoids some weird squashed fields.
        styleset.height = `${sniffField.offsetHeight}px`;
      }

      // Let's treat the custom CSS as an override of anything we've sniffed if we have it
      // and otherwise just a handful of isolated rules.
      if (this.config.customCss) {
        Object.entries(this.config.customCss).forEach(entry => {
          const [key, value] = entry;
          customStyleset[key] = value;
        });
      }

      if (this.config.focusCss) {
        Object.entries(this.config.focusCss).forEach(entry => {
          const [key, value] = entry;
          focusStyleset[key] = value;
        });
      }

      if (this.config.invalidCss) {
        Object.entries(this.config.invalidCss).forEach(entry => {
          const [key, value] = entry;
          invalidStyleset[key] = value;
        });
      }

      if (this.config.validCss) {
        Object.entries(this.config.validCss).forEach(entry => {
          const [key, value] = entry;
          validStyleset[key] = value;
        });
      }

      if (this.config.placeholderCss) {
        Object.entries(this.config.placeholderCss).forEach(entry => {
          const [key, value] = entry;
          placeholderStyleset[key] = value;
        });
      }

      const savedStyle = {
        action: 'styling',
        style: styleset,
        customStyle: customStyleset,
        invalidStyle: invalidStyleset,
        validStyle: validStyleset,
        placeholderStyle: placeholderStyleset,
        focusStyle: focusStyleset,
        token,
        googleFont: this.config.googleFont,
      };

      if (this.config.styleSniffer) {
        element.removeChild(sniffField);
      }

      const iframe = document.createElement('iframe');
      iframe.id = `CollectJSInline${elementType}`;
      iframe.classList.add('CollectJSInlineIframe');
      iframe.width = '100%';
      iframe.scrolling = 'no';
      iframe.height = '22px';
      iframe.style.display = 'block';
      iframe.setAttribute(
        'src',
        `${UrlParser.inlineUrl}?${this.config.inlineParams(elementType, token)}`,
      );
      iframe.onload = () => {
        iframe.contentWindow.postMessage(savedStyle, UrlParser.origin);
      };

      // If CollectJS.buildInlineIframes in run multiple times, we should remove the old iframes before replacing them.
      // In the future, we may just want to remount them rather than creating new iframes.
      if (
        this.iframes[elementType] instanceof Node &&
        element.contains(this.iframes[elementType])
      ) {
        element.removeChild(this.iframes[elementType]);
      }

      element.appendChild(iframe);
      this.iframes[elementType] = iframe;
    });
    this[safariFix].setIframes(this.iframes);
  }

  async buildGooglePayButton(token, merchantId, merchantName, currencies, cardBrands) {
    const googlePayField = this.fieldFactory.create('googlePay', {
      country: this.config.country,
      price: this.config.price,
      currency: this.config.currency,
      currencies,
      billingAddressRequired: this.config.fields.googlePay.billingAddressRequired,
      billingAddressParameters: this.config.fields.googlePay.billingAddressParameters,
      shippingAddressRequired: this.config.fields.googlePay.shippingAddressRequired,
      shippingAddressParameters: this.config.fields.googlePay.shippingAddressParameters,
      buttonType: this.config.fields.googlePay.buttonType,
      buttonColor: this.config.fields.googlePay.buttonColor,
      buttonLocale: this.config.fields.googlePay.buttonLocale,
      totalPriceStatus: this.config.fields.googlePay.totalPriceStatus,
      emailRequired: this.config.fields.googlePay.emailRequired,
      merchantId,
      merchantName,
      cardBrands,
      environment: isDevelopment() ? 'TEST' : 'PRODUCTION',
      token,
      tokenizationKey: this.config.tokenizationKey,
      cartCorrelationId: this.config.cartCorrelationId,
    });

    if (!(googlePayField instanceof OuterGooglePayField)) {
      return;
    }

    googlePayField.unmount(this.config.fields.googlePay.selector);
    googlePayField.mount(this.config.fields.googlePay.selector);

    const onComplete = async paymentInfo => {
      try {
        await TokenService.updateToken(
          token,
          this.config.tokenizationKey,
          [googlePayField],
          this.config.timeoutDuration,
          this.config.cartCorrelationId,
        );
      } catch {
        this.config.timeoutCallback();
      }
      const initiatedByEvent = await Field.generateFakeEvent(this.config.fields.googlePay.selector);
      const tokenData = await Field.lookupAndFormatToken(
        token,
        this.config.tokenizationKey,
        'visa',
        this.config.cartCorrelationId,
      );

      const wallet = OuterGooglePayField.getWalletDataFromPaymentData(paymentInfo);
      const tokenResponse = new TokenResponse(
        'googlePay',
        token,
        initiatedByEvent,
        tokenData,
        wallet,
      );
      this.config.callback(tokenResponse);
      this.retokenize();
    };
    googlePayField.removeListener('complete', onComplete);
    googlePayField.on('complete', onComplete);
  }

  async buildApplePayButton(token, currencies, cardBrands, allowedApplePayDomains) {
    const applePayField = this.fieldFactory.create('applePay', {
      country: this.config.country,
      price: this.config.price,
      currency: this.config.currency,
      currencies,
      shippingMethods: this.config.fields.applePay.shippingMethods,
      shippingType: this.config.fields.applePay.shippingType,
      requiredBillingContactFields: this.config.fields.applePay.requiredBillingContactFields,
      requiredShippingContactFields: this.config.fields.applePay.requiredShippingContactFields,
      contactFields: this.config.fields.applePay.contactFields,
      contactFieldsMappedTo: this.config.fields.applePay.contactFieldsMappedTo,
      lineItems: this.config.fields.applePay.lineItems,
      totalLabel: this.config.fields.applePay.totalLabel,
      totalType: this.config.fields.applePay.totalType,
      type: this.config.fields.applePay.type,
      cardBrands,
      token,
      tokenizationKey: this.config.tokenizationKey,
      cartCorrelationId: this.config.cartCorrelationId,
      allowedApplePayDomains,
      isRecurringTransaction: this.config.fields.applePay.isRecurringTransaction,
      recurringPaymentDescription: this.config.fields.applePay.recurringPaymentDescription,
      recurringBillingAgreement: this.config.fields.applePay.recurringBillingAgreement,
      recurringRegularBilling: this.config.fields.applePay.recurringRegularBilling,
      recurringManagementUrl: this.config.fields.applePay.recurringManagementUrl,
      recurringTokenNotificationUrl: this.config.fields.applePay.recurringTokenNotificationUrl,
      recurringMismatchCallback: this.config.fields.applePay.recurringMismatchCallback,
      applePayVersion: this.applePayVersion,
    });

    if (!(applePayField instanceof ApplePayField)) {
      return;
    }

    applePayField.style({
      'border-radius': this.config.fields.applePay.style['border-radius'],
      'button-style': this.config.fields.applePay.style['button-style'],
      height: this.config.fields.applePay.style.height,
    });

    applePayField.unmount(this.config.fields.applePay.selector);
    applePayField.mount(this.config.fields.applePay.selector);

    const onComplete = async event => {
      try {
        await TokenService.updateToken(
          token,
          this.config.tokenizationKey,
          [applePayField],
          this.config.timeoutDuration,
          this.config.cartCorrelationId,
        );
      } catch {
        this.config.timeoutCallback();
      }
      const initiatedByEvent = await Field.generateFakeEvent(this.config.fields.applePay.selector);
      const tokenData = await Field.lookupAndFormatToken(
        token,
        this.config.tokenizationKey,
        'visa',
        this.config.cartCorrelationId,
      );
      const wallet = ApplePayField.getWalletDataFromPaymentData(event);
      const tokenResponse = new TokenResponse(
        'applePay',
        token,
        initiatedByEvent,
        tokenData,
        wallet,
      );
      this.config.callback(tokenResponse);
      this.retokenize();
    };
    applePayField.removeListener('complete', onComplete);
    applePayField.on('complete', onComplete);
  }

  /**
   * @param event
   */
  startPaymentRequest(event) {
    this.tokenPromise.then(
      ({ token }) => {
        if (this.config.variant === 'lightbox') {
          if (this.isIframeOpen) {
            throw new Error("'startPaymentRequest' may not be called while the lightbox is open.");
          } else {
            this.isIframeOpen = true;
          }
          // This prevents an issue where a button inside a form would submit the form immediately after
          // opening the iframe.
          if (event instanceof Event) {
            event.preventDefault();
            this.initiatedBy = event;
          }

          const body = document.querySelector('body');

          this.backgroundDiv = document.createElement('div');
          this.backgroundDiv.classList.add('CollectJSFade');
          this.backgroundDiv.addEventListener(
            'click',
            () => {
              this.closePaymentRequest();
            },
            false,
          );

          this.iframe = document.createElement('iframe');
          this.iframe.id = 'CollectJSIframe';
          this.iframe.classList.add('CollectJSBounceIn');
          this.iframe.scrolling = 'no';
          this.iframe.height = '220px';
          this.iframe.width = '350px';
          this.iframe.style.borderColor = this.config.secondaryColor;

          body.appendChild(this.backgroundDiv);

          // we have to wait for the element to be rendered before applying the opacity change.
          // If we change it too soon, the transition effect doesn't get applied
          // However there isn't an event that triggers when the element is fully rendered.
          // This recommends a 0 second timeout but this was upped to 50 for safety
          // https://stackoverflow.com/questions/15875128/how-to-tell-when-a-dynamically-created-element-has-rendered
          window.setTimeout(() => {
            this.backgroundDiv.style.opacity = '0.5';
            body.appendChild(this.iframe);
            this.iframe.setAttribute(
              'src',
              `${UrlParser.lightboxUrl}?${this.config.lightboxParams(token)}`,
            );
            this.iframe.addEventListener('load', () => {
              this.iframe.style.display = 'block';
            });
          }, 50);
        } else if (this.config.variant === 'inline') {
          if (event instanceof Event) {
            event.preventDefault();
            this.initiatedBy = event;
          }
          if (!this.inSubmission) {
            this.inSubmission = true;
            MessageHandler.completedIframes = [];
            if (this.config.timeoutDuration) {
              this.responseTimeout = window.setTimeout(() => {
                MessageHandler.completedIframes = [];
                if (this.config.timeoutCallback) {
                  this.config.timeoutCallback();
                } else {
                  throw new Error('Please submit the form again.');
                }
                this.inSubmission = false;
              }, this.config.timeoutDuration);
            }
            Object.keys(this.iframes).forEach(key => {
              // Check to make sure that the content window still exists (e.g. its still on the page)
              if (this.iframes[key].contentWindow) {
                this.iframes[key].contentWindow.postMessage(
                  {
                    token,
                    action: 'SaveMultipartToken',
                  },
                  '*',
                );
              }
            });
          }
        }
      },
      () => {
        // eslint-disable-next-line no-console
        console.log("Can't launch payment request without a valid token");
      },
    );
  }

  /**
   *
   */
  closePaymentRequest() {
    this.iframe.classList.remove('CollectJSBounceIn');
    this.iframe.addEventListener('animationend', () => {
      this.iframe.style.display = 'none';
      this.iframe.remove();
      this.backgroundDiv.style.opacity = 0;
      this.backgroundDiv.remove();
      this.isIframeOpen = false;
    });
    this.iframe.classList.add('CollectJSBounceOut');
  }

  clearInputs() {
    if (this.config.variant !== 'inline') {
      return;
    }
    Object.keys(this.iframes).forEach(key => {
      this.iframes[key].contentWindow.postMessage(
        {
          action: 'ClearInput',
        },
        '*',
      );
    });
  }
}

window.CollectJS = new CollectJS();

const link = document.createElement('link');
link.setAttribute('href', UrlParser.stylesheetUrl);
link.setAttribute('rel', 'stylesheet');
UrlParser.currentScriptNode.parentNode.insertBefore(link, UrlParser.currentScriptNode);
