import ApplePayService from './ApplePayService';

function allAllLettersUpperCase(str) {
  return str.split('').filter(char => char !== char.toUpperCase()).length > 0;
}

function isNumeric(str) {
  return Number.isNaN(parseFloat(str));
}

class ApplePayRequest {
  constructor(
    currency,
    price,
    country,
    cardBrands,
    shippingMethods,
    shippingType,
    requiredBillingContactFields,
    requiredShippingContactFields,
    contactFields,
    contactFieldsMappedTo,
    lineItems,
    totalLabel,
    totalType,
    tokenizationKey,
    cartCorrelationId,
    isRecurringTransaction,
    recurringPaymentDescription,
    recurringBillingAgreement,
    recurringRegularBilling,
    recurringManagementUrl,
    recurringTokenNotificationUrl,
    domainName,
    canMakePaymentsPromise,
  ) {
    this.currency = currency;
    this.price = price;
    this.country = country;
    this.cardBrands = cardBrands;
    this.shippingMethods = shippingMethods;
    this.shippingType = shippingType;
    this.requiredBillingContactFields = requiredBillingContactFields;
    this.requiredShippingContactFields = requiredShippingContactFields;
    this.contactFields = contactFields;
    this.contactFieldsMappedTo = contactFieldsMappedTo;
    this.lineItems = lineItems;
    this.totalLabel = totalLabel;
    this.totalType = totalType;
    this.domainName = domainName;
    this.tokenizationKey = tokenizationKey;
    this.cartCorrelationId = cartCorrelationId;
    this.isRecurringTransaction = isRecurringTransaction;
    this.recurringPaymentDescription = recurringPaymentDescription;
    this.recurringBillingAgreement = recurringBillingAgreement;
    this.recurringRegularBilling = recurringRegularBilling;
    this.recurringManagementUrl = recurringManagementUrl;
    this.recurringTokenNotificationUrl = recurringTokenNotificationUrl;

    this.canMakePayment = canMakePaymentsPromise;

    this.paymentResponse = null;
  }

  static getOptionErrors(options) {
    const errors = [];

    if (
      typeof options.currency !== 'string' ||
      options.currency.length !== 3 ||
      allAllLettersUpperCase(options.currency)
    ) {
      errors.push('Currency must be an uppercase, valid, 3 letter currency code.');
    }

    if (typeof options.price !== 'string' || !isNumeric(options.price)) {
      errors.push('Price must be a numeric string.');
    }

    if (
      typeof options.country !== 'string' ||
      options.country.length !== 2 ||
      allAllLettersUpperCase(options.country)
    ) {
      errors.push('Country must be an uppercase, valid, 2 letter country code.');
    }

    return errors;
  }

  static areOptionsValid(options) {
    return ApplePayRequest.getOptionErrors(options).length === 0;
  }

  static create(options) {
    if (ApplePayRequest.areOptionsValid(options)) {
      // eslint-disable-next-line no-console
      console.error(
        'Could not create PaymentRequestAbstraction. Please verify the provided options are valid.',
      );
      return null;
    }

    const canMakePaymentsPromise = ApplePayService.canMakePayments(options).catch(() => {});

    const applePayRequest = new ApplePayRequest(
      options.currency,
      options.price,
      options.country,
      options.cardBrands,
      options.shippingMethods,
      options.shippingType,
      options.requiredBillingContactFields,
      options.requiredShippingContactFields,
      options.contactFields,
      options.contactFieldsMappedTo,
      options.lineItems,
      options.totalLabel,
      options.totalType,
      options.tokenizationKey,
      options.cartCorrelationId,
      options.isRecurringTransaction,
      options.recurringPaymentDescription,
      options.recurringBillingAgreement,
      options.recurringRegularBilling,
      options.recurringManagementUrl,
      options.recurringTokenNotificationUrl,
      options.domainName,
      canMakePaymentsPromise,
    );

    applePayRequest.canMakePayment.then(applePaySupport => {
      if (
        applePaySupport !== undefined &&
        (applePaySupport.areRecurrentPaymentSupported === false ||
          (options.applePayVersion !== undefined && options.applePayVersion < 14)) &&
        options.isRecurringTransaction === true
      ) {
        if (options.recurringMismatchCallback) {
          options.recurringMismatchCallback();
        } else {
          throw new Error(
            'Apple Pay version not supported. Please update your Apple Pay or use a different payment method.',
          );
        }
      }
    });

    return applePayRequest;
  }

  async capturePayment() {
    this.applePayResponse = await ApplePayService.begin(this);
    return this.applePayResponse;
  }
}

export default ApplePayRequest;
